import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { Checkbox } from '../Forms';

import Styles from './HorizontalCheckboxCard.module.scss';
import { Props } from './HorizontalCheckboxCard.types';

const HorizontalCheckboxCard = ({
  variant,
  title,
  subtitles,
  className,
  rightActions,
  leftIcon,
  classNameLeftIcon,
  leftSideClassName,
  titleClassName,
  subtitlesClassName,
  checkboxContainerClassName,
  selected,
  onSelectionChange,
  subtitleActions,
  error,
}: Props) => {
  const [pressed, setPressed] = useState(false);

  const setUnpressed = useCallback(() => setPressed(false), []);
  useEffect(() => {
    document.addEventListener('mouseup', setUnpressed);
    return () => document.removeEventListener('mouseup', setUnpressed);
  }, [setUnpressed]);

  return (
    <div
      role="checkbox"
      aria-checked={selected}
      aria-label={title}
      tabIndex={0}
      onKeyUp={event => {
        if (event.key === ' ' || event.key === 'Enter') {
          onSelectionChange(!selected);
        }
      }}
      onMouseDown={() => setPressed(true)}
      onMouseUp={() => setPressed(false)}
      className={classnames(
        Styles.container,
        Styles[variant],
        selected && Styles.selected,
        error && Styles.error,
        pressed && Styles.pressed,
        className
      )}
      onClick={() => onSelectionChange(!selected)}
    >
      <div className={classnames(Styles.checkboxContainer, checkboxContainerClassName)}>
        <Checkbox checked={selected} onChange={() => onSelectionChange(!selected)} />
      </div>
      <div style={{ flex: 1 }} className={classnames(Styles.leftSide, leftSideClassName)}>
        {leftIcon && (
          <div className={classnames(Styles.leftIcon, classNameLeftIcon)}>{leftIcon}</div>
        )}
        <div className={Styles.leftTextContainer}>
          <div className={classnames(Styles.title, titleClassName)}>{title}</div>
          {subtitleActions
            ? subtitleActions
            : Boolean(subtitles && subtitles.length) && (
                <div className={classnames(Styles.description, subtitlesClassName)}>
                  {subtitles?.map((subtitle, idx) => (
                    <div key={idx}>{subtitle}</div>
                  ))}
                </div>
              )}
        </div>
      </div>
      {rightActions}
    </div>
  );
};

export default HorizontalCheckboxCard;
