import classnames from 'classnames';
import React from 'react';

import styles from './TextArea.module.scss';
import { Props } from './TextArea.types';

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(({ className, ...props }, ref) => {
  return (
    <textarea
      ref={ref}
      className={classnames(styles.container, styles.input, className)}
      {...props}
    />
  );
});

export default TextArea;
