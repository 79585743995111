import { Filters } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';

import Button from '../Buttons';
import { PopupMenu } from '../Popups';

import styles from './Kanban.module.scss';
import { Props } from './Kanban.types';

const Kanban = ({ title, className, children, filterPopupContent, height }: Props) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.title}>{title}</p>
        {filterPopupContent && (
          <PopupMenu
            children={filterPopupContent}
            onOpen={() => setIsFilterOpen(true)}
            onClose={() => {
              setIsFilterOpen(false);
            }}
            trigger={
              <Button.Tertiary
                className={classnames(styles.trigger, { [styles.activeTrigger]: isFilterOpen })}
              >
                <Filters className={styles.icon} />
              </Button.Tertiary>
            }
            position="left top"
          />
        )}
      </div>
      <div className={classnames(styles.kanbanContainer, className)} style={{ height: height }}>
        <div className={styles.fullWidth}>
          <div className={styles.scrollableComponent}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Kanban;
